import jQuery from 'jquery'
import * as Vue from 'vue'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as bootstrap from 'bootstrap'
import * as VueLeaflet from '@vue-leaflet/vue-leaflet'
import * as ProgressBar from 'progressbar.js'
import ApexCharts from 'apexcharts'
import Lodash from 'lodash'

import 'vuetify/styles'
import 'bootstrap/dist/css/bootstrap.css' assert { type: "css" }
import 'bootstrap-icons/font/bootstrap-icons.css' assert { type: "css" }
import '@mdi/font/css/materialdesignicons.css' assert { type: "css" }
import 'leaflet/dist/leaflet.css' assert { type: "css" }
import 'apexcharts/dist/apexcharts.css' assert { type: "css" }
import './css/fonts.css' assert { type: "css" }
import './css/styles.css' assert { type: "css" }

// see: https://vuetifyjs.com/en/api/use-theme/#exposed
const meshmerizeTheme = {
    dark: false,
    colors: {
        background: '#ffffff',
        surface: '#ffffff',
        primary: '#1e6aa8',
        secondary: '#4b994b',
        accent: '#63d77c',
        error: '#ec4040',
        warning: '#e3a11f',
        info: '#69a9d6',
        success: '#24a942',
    },
}

window.jQuery = jQuery
window.$ = jQuery
window.Vue = Vue
window.VueLeaflet = VueLeaflet
window.bootstrap = bootstrap
window.ProgressBar = ProgressBar
window.ApexCharts = ApexCharts
window._ = Lodash

window.vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'meshmerize',
        themes: {
            meshmerize: meshmerizeTheme,
        }
    }
})
